<script lang="ts" setup>
const { t } = useI18n();
</script>

<template>
  <section class="sectionCompanySlogan">
    <p class="sectionCompanySlogan__text">{{ t('slogan') }}</p>
    <NuxtLink to="/about" class="sectionCompanySlogan__link">
      <BaseButton size="s" outlined dark class="sectionCompanySlogan__button">
        {{ t('more') }}
      </BaseButton>
    </NuxtLink>
  </section>
</template>

<i18n>
ru:
  slogan: Разрабатывая продукты Don’t Touch My Skin, мы всегда стремимся к безопасности, комфорту и здоровью
  more: Подробнее о бренде

en:
  slogan: When developing Don’t Touch My Skin products, we always strive for safety, comfort, and health
  more: More about the brand
</i18n>

<style lang="scss">
.sectionCompanySlogan {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 38px;
  align-items: center;

  @include mq('sm') {
    gap: 24px;
  }

  &__text {
    font-size: 56px;
    line-height: 64px;
    text-align: center;
    color: #121212;
    max-width: 1106px;
    margin-top: 31px;
    margin-bottom: 0;

    @include mq('lg') {
      font-size: 44px;
      line-height: 120%;
    }

    @include mq('sm') {
      font-size: 24px;
      line-height: 32px;
      margin-top: 0;
    }
  }

  &__link {
    @include mq('sm') {
      width: 100%;
    }
  }

  &__button {
    padding-left: 53px;
    padding-right: 53px;

    @include mq('sm') {
      padding: 0;
      width: 100%;
    }
  }
}
</style>
