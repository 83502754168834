<script lang="ts" setup>
import type { MainPageNewsItemType } from '~/api/common';

const props = defineProps<{
  news: MainPageNewsItemType[];
}>();

const { t } = useI18n();

const newsList = computed(() => props.news.map((el, id) => ({ ...el, id })));
</script>

<template>
  <BaseSliderSection
    v-if="newsList.length"
    class="sectionNewsSentences"
    slider-class="sectionNewsSentences__slider"
    slider-slide-class="sectionNewsSentences__slide"
    navigation-class="sectionNewsSentences__navigation"
    :list="newsList"
    slides-per-view="auto"
  >
    <template #title>{{ t('title') }}</template>
    <template #slide="{ item }">
      <BaseNewsCard
        :link="item.link"
        :image="item.picture"
        :title="item.name"
      />
    </template>
  </BaseSliderSection>
</template>

<i18n>
ru:
  title: Самые актуальные новости

en:
  title: The most relevant news
</i18n>

<style lang="scss">
.sectionNewsSentences {
  & &__slider {
    padding: 0 20px;

    @include mq('sm') {
      padding: 0 16px;
    }
  }

  & &__slide {
    width: fit-content;

    @include mq('sm') {
      width: 100%;
    }
  }

  & &__navigation {
    top: calc(337px / 2);
  }
}
</style>
