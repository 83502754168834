<script lang="ts" setup>
import type { ProductType } from '~/api/common';

const props = defineProps<{
  withShowMore?: boolean;
  hitProducts: ProductType[];
  newProducts: ProductType[];
}>();

const { t } = useI18n();

const isPopular = ref(!!props.hitProducts.length);

const selectedProducts = computed(() =>
  isPopular.value ? props.hitProducts : props.newProducts,
);
</script>

<template>
  <BaseSliderSection
    v-if="selectedProducts?.length"
    class="productSentences"
    slider-class="productSentences__slider"
    navigation-class="productSentences__navigation"
    :list="selectedProducts"
    :slides-per-view="{ tablet: 2, desktop: 4 }"
    :action-link="withShowMore ? '/catalog' : undefined"
    mobile-grid
    with-padding-top
  >
    <template #header>
      <button
        v-if="hitProducts.length"
        @click="isPopular = true"
        class="productSentences__button"
        :class="{ productSentences__button_secondary: !isPopular }"
      >
        {{ t('popular') }}
      </button>
      <button
        v-if="newProducts.length"
        @click="isPopular = false"
        class="productSentences__button"
        :class="{ productSentences__button_secondary: isPopular }"
      >
        {{ t('new') }}
      </button>
    </template>

    <template #slide="{ item }">
      <ProductCard :product="item" />
    </template>
  </BaseSliderSection>
</template>

<i18n>
ru:
  popular: Популярное
  new: Новинки

en:
  popular: Popular
  new: New
</i18n>

<style lang="scss">
.productSentences {
  &__button {
    background: none;
    border: none;
    cursor: pointer;
    font: inherit;
    line-height: inherit;
    transition: opacity 0.3s ease;
    padding: 0;
    color: #121212;

    &_secondary {
      opacity: 0.3;
    }
  }

  & &__slider {
    padding: 0 18px;
  }

  & &__navigation {
    top: calc(
      ((100vw - 18px * 5) / 4) * (444 / 338 / 2) + var(--navigation-delta)
    );

    @include mq('lg') {
      top: calc(
        ((100vw - 18px * 3) / 2) * (444 / 338 / 2) + var(--navigation-delta)
      );
    }
  }
}
</style>
