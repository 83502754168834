<script lang="ts" setup>
import type { MainPageBannerType } from '~/api/common';
import nameLogo from '~/assets/icons/logo/name.svg?url';

const SLIDE_DURATION = 8000;

const props = defineProps<{
  banners: MainPageBannerType[];
}>();

const image = useImage();
const { t } = useI18n();

const isActiveSlider = ref(true);
const activeTimeState = ref(0);
const activeSlideStartTime = ref(0);
const prevIndex = ref(0);
const activeIndex = ref(0);

const activeSlide = computed(() => props.banners[activeIndex.value]);
const sliderPinScale = computed(() => activeTimeState.value / SLIDE_DURATION);
const sliderImages = computed(() =>
  props.banners.map((item) => {
    const mobile = image.getSizes(item.mobileImage, {
      sizes: IMAGE_SIZES.mobileFullscreen,
      modifiers: { format: 'webp' },
    });
    const desktop = image.getSizes(item.desktopImage, {
      sizes: IMAGE_SIZES.desktopFullscreen,
      modifiers: { format: 'webp' },
    });

    return {
      alt: item.name || t('slide_alt'),
      src: image(item.desktopImage, { format: 'webp' }),
      mobile,
      desktop,
    };
  }),
);

const onChangeActiveSlide = (index: number) => {
  if (activeIndex.value === index) return;
  prevIndex.value = activeIndex.value;
  activeIndex.value = index;
  activeTimeState.value = 0;
  activeSlideStartTime.value = Date.now();
};

onMounted(() => {
  activeSlideStartTime.value = Date.now();
  const onNext = () => {
    requestAnimationFrame(() => {
      activeTimeState.value = Date.now() - activeSlideStartTime.value;

      if (activeTimeState.value > SLIDE_DURATION) {
        prevIndex.value = activeIndex.value;
        activeIndex.value += 1;
        activeTimeState.value = 0;
        activeSlideStartTime.value = Date.now();
        if (activeIndex.value === props.banners.length) activeIndex.value = 0;
      }

      if (isActiveSlider) onNext();
    });
  };

  onNext();
});

onBeforeUnmount(() => {
  isActiveSlider.value = false;
});
</script>

<template>
  <section v-if="banners.length" class="welcomeSection">
    <TransitionGroup name="slider-image">
      <div
        v-for="(item, index) in sliderImages"
        :key="index"
        v-show="activeIndex === index"
        class="welcomeSection__image"
      >
        <picture>
          <source
            :srcset="item.mobile.srcset"
            :sizes="item.mobile.sizes"
            media="(max-width: 767px)"
          />
          <source
            :srcset="item.desktop.srcset"
            :sizes="item.desktop.sizes"
            media="(min-width: 768px)"
          />
          <img :src="item.src" :alt="item.alt" />
        </picture>
      </div>
    </TransitionGroup>

    <div class="welcomeSection__info">
      <Transition name="slider-description" mode="out-in">
        <p
          v-if="activeSlide.name"
          :key="activeIndex"
          class="welcomeSection__description"
        >
          {{ activeSlide.name }}
        </p>
      </Transition>
      <NuxtLink :to="activeSlide.url">
        <BaseButton class="welcomeSection__action">{{ t('more') }}</BaseButton>
      </NuxtLink>
    </div>

    <div class="welcomeSection__indicator">
      <button
        v-for="(_, index) in banners"
        :key="index"
        @click="onChangeActiveSlide(index)"
        :aria-label="t('go_to_slide', [index + 1])"
        class="welcomeSection__pin"
        :class="{ welcomeSection__pin_active: activeIndex === index }"
      />
    </div>

    <img
      :src="nameLogo"
      alt="Don't Touch My Skin"
      class="welcomeSection__logo"
    />
  </section>
</template>

<i18n>
ru:
  more: Подробнее
  slide_alt: Изображение слайдера
  go_to_slide: Переключиться на слайд номер {0}

en:
  more: More
  slide_alt: Slider image
  go_to_slide: Go to slide number {0}
</i18n>

<style lang="scss">
.welcomeSection {
  $self: &;

  height: 100svh;
  position: relative;
  overflow: hidden;
  background-color: #121212;

  &__logo {
    position: absolute;
    top: 30px;
    left: 21px;

    @include mq('sm') {
      width: 121px;
      height: 126px;
    }
  }

  &__image {
    position: absolute;
    height: 100%;
    width: 100%;

    img {
      object-fit: cover;
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  .slider-image-enter-active,
  .slider-image-leave-active {
    transition: transform 0.5s ease;
    display: block;
  }

  .slider-image-enter-from {
    transform: translateX(100%);
  }

  .slider-image-leave-to {
    transform: translateX(-100%);
  }

  &__info {
    position: absolute;
    left: 21px;
    bottom: 51px;
  }

  &__description {
    font-style: normal;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.2px;
    color: #ffffff;
    max-width: 672px;
    margin: 0 0 30px;

    @include mq('sm') {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px;
    }
  }

  &__action {
    margin-left: 3px;
  }

  &__indicator {
    max-width: 520px;
    display: flex;
    align-items: center;
    gap: 17px;
    position: absolute;
    right: 20px;
    bottom: 51px;
    height: 2px;

    @include mq('sm') {
      max-width: initial;
      width: 100%;
      bottom: 18px;
      right: initial;
      justify-content: center;
      padding: 0 16px;
    }
  }

  &__pin {
    height: 8px;
    width: 89px;
    background: none;
    position: relative;
    cursor: pointer;
    padding: 0;
    outline: none;
    transition: transform 0.3s ease;
    border: none;

    &:hover {
      &::before,
      &::after {
        height: 8px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      height: 2px;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 100%;
      transition: height 0.3s ease;
      background-color: rgba(255, 255, 255, 0.2);
      transform-origin: 0 0;
    }

    &::after {
      content: '';
      position: absolute;
      height: 2px;
      display: block;
      width: 100%;
      top: 50%;
      transform: translateY(-50%) scaleX(0);
      left: 0;
      background-color: #fff;
      transition: transform 0.5s ease;
      transform-origin: 0 0;
    }

    &_active::after {
      transition: height 0.3s ease;
      transform: translateY(-50%) scaleX(v-bind(sliderPinScale));
    }

    &:not(&_active)::after {
      transition:
        transform 0.5s ease,
        left 0.5s ease,
        height 0.3s ease;
      left: 100%;
    }

    @include mq('sm') {
      width: 67px;
    }
  }

  .slider-description-enter-active,
  .slider-description-leave-active {
    transition:
      transform 0.3s ease,
      opacity 0.3s ease;
  }

  .slider-description-enter-from {
    transform: translateY(-70%);
    opacity: 0;
  }

  .slider-description-leave-to {
    transform: translateY(70%);
    opacity: 0;
  }
}
</style>
