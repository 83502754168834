<script lang="ts" setup>
import type { PagesMainResponseType, ProductType } from '~/api/common';
import CommonAPI from '~/api/common';

const commonStore = useCommonStore();
const mainPageData = useNuxtData<PagesMainResponseType>('main-page-data');

const { data: mainPage } = await useAsyncData<PagesMainResponseType>(() =>
  mainPageData.data.value
    ? Promise.resolve(mainPageData.data.value)
    : CommonAPI.main(),
);
if (mainPage.value) mainPageData.data.value = mainPage.value;

const newProducts = computed(
  () =>
    (mainPage.value?.newProductIds || [])
      .map((id) => commonStore.products.find((product) => product.id === id))
      .filter(Boolean) as ProductType[],
);
const hitProducts = computed(
  () =>
    (mainPage.value?.hitProductIds || [])
      .map((id) => commonStore.products.find((product) => product.id === id))
      .filter(Boolean) as ProductType[],
);
</script>

<template>
  <main class="mainPage">
    <template v-if="mainPage">
      <SectionWelcomeSlider :banners="mainPage.banners" />
      <SectionProductSentences
        :hit-products="hitProducts"
        :new-products="newProducts"
        with-show-more
        class="mainPage__productSentences"
      />
    </template>
    <SectionBanners class="mainPage__bannersSection" />
    <SectionCompanySlogan class="mainPage__companySlogan" />
    <SectionEmptyBanner class="mainPage__emptyBanner" />
    <SectionMediaSentences id="playlists" class="mainPage__mediaSentences" />
    <!--    <SectionSubscriptionBanner class="mainPage__subscriptionBanner" />-->
    <SectionSubscribe class="mainPage__subscribeSection" />
    <SectionNewsSentences
      v-if="mainPage"
      :news="mainPage.news"
      class="mainPage__newsSection"
    />
  </main>
</template>

<style lang="scss">
.mainPage {
  &__emptyBanner {
    margin-top: 53px;

    @include mq('sm') {
      display: none;
    }
  }

  &__productSentences {
    margin-top: 47px;
  }

  &__bannersSection {
    margin-top: 58px;
  }

  &__companySlogan {
    @include mq('sm') {
      padding-bottom: 10px;
    }
  }

  &__mediaSentences {
    margin-top: 67px;

    @include mq('sm') {
      margin-top: 7px;
    }
  }

  &__subscriptionBanner {
    margin-top: 80px;
  }

  &__subscribeSection {
    margin-top: 60px;
  }

  &__newsSection {
    margin-top: 24px;
    margin-bottom: 49px;
  }
}
</style>
