<script lang="ts" setup>
import image1 from '~/assets/images/anons1.png';
import image2 from '~/assets/images/anons2.png';

type BannerInfoType = {
  id: number;
  title: string;
  image: string;
  description?: string;
  actions: {
    label: string;
    download?: string;
    to: string;
    outlined?: boolean;
  }[];
};

const { t } = useI18n();

const DATA: BannerInfoType[] = [
  {
    id: 1,
    image: image1,
    title: t('banners.0.title'),
    actions: [{ label: t('banners.0.action'), to: '/sustainable' }],
  },
  {
    id: 2,
    image: image2,
    title: t('banners.1.title'),
    actions: [
      {
        label: t('banners.1.action'),
        download: t('banners.1.file'),
        to: '/skin-care-instruction.pdf',
      },
    ],
  },
];
</script>

<template>
  <section class="sectionBanners">
    <BaseBannerCard
      v-for="item in DATA"
      :key="item.id"
      :title="item.title"
      :image="item.image"
      :description="item.description"
      :actions="item.actions"
      :overflow="DATA.length > 2"
    />
  </section>
</template>

<i18n>
ru:
  banners:
    - title: Устойчивое развитие
      action: Смотреть подробнее
    - title: Гид по подбору ухода
      action: Смотреть подробнее
      file: Как выстроить уход за кожей.pdf

en:
  banners:
    - title: Sustainable development
      action: Learn more
    - title: Skin care guide
      action: Learn more
      file: How to build a skin care routine.pdf
</i18n>

<style lang="scss">
.sectionBanners {
  padding: 20px;
  display: flex;
  gap: 20px;

  @include mq('lg') {
    flex-direction: column;
  }

  @include mq('lg', 'sm') {
    & > *:nth-child(3) {
      display: none;
    }
  }

  @include mq('sm') {
    gap: 32px;
    padding: 20px 16px;
  }
}
</style>
