<script lang="ts" setup>
import { queryContent, useAsyncData } from '#imports';
import type { ArticleType } from '~/api/blog';
import type { MediaType } from '~/api/common';
import getRandomValue from '~/utils/getRandomValue';

// import playlistImage1 from '~/assets/images/playlists/dont-touch-my-girl-power.jpg';
// import playlistImage2 from '~/assets/images/playlists/dont-touch-my-space.jpg';
// import playlistImage3 from '~/assets/images/playlists/dont-touch-my-valentine.jpg';

const { t, locale } = useI18n();

const PLAYLISTS: MediaType[] = [];
// TODO: Нужно ли еще
// const PLAYLISTS = [
//   {
//     id: '1',
//     image: playlistImage1,
//     category: 'Плейлист',
//     title: "DON'T TOUCH MY GIRL POWER",
//     description:
//       'Мы очень любим молодых российских исполнительниц. В этом плейлисте собрали композиции, которые нам особенно нравятся!',
//     action: 'Слушать',
//     url: 'https://www.youtube.com/watch?v=Bckkno7-GCE&list=PLfUp93T3FV6FmG57XQzUs1pzufRgWy0JZ',
//   },
//   {
//     id: '2',
//     image: playlistImage2,
//     category: 'Плейлист',
//     title: 'DON’T TOUCH MY WINTER VIBES',
//     description:
//       'Зимой часто хочется забыть по все дела и просто почилить под одеялом. А у нас есть микс песен, под который особенно приятно это делать.',
//     action: 'Слушать',
//     url: 'https://open.spotify.com/embed/playlist/7Hzlc9vOxwDOkwK2DLASLy',
//   },
//   {
//     id: '3',
//     image: playlistImage3,
//     category: 'Плейлист',
//     title: 'DON‘T TOUCH MY VALENTINE',
//     description:
//       'Любовь, как и музыка, безгранична. В этом плейлисте вы найдете композиции, которые порадуют вас и ваших любимых.',
//     action: 'Слушать',
//     url: 'https://embed.music.apple.com/ru/playlist/don-t-touch-my-valentine/pl.u-oZyl4J1soD9dkR',
//   },
// ];

const { data: articles } = await useAsyncData<ArticleType[]>(
  'media-articles',
  () =>
    queryContent<ArticleType>(`/${locale.value}/blog`)
      .where({ sections: { media: true } })
      .only(['_path', 'title', 'image'] as unknown as keyof ArticleType)
      .sort({ order: 1 })
      .limit(4)
      .find(),
);

const list = computed<MediaType[]>(() => {
  const playlistsWithScore = PLAYLISTS.map((el, i) => ({
    item: el,
    score: (i + 1) / (PLAYLISTS.length + 1) + (getRandomValue() - 0.5) / 1000,
  }));
  const articlesWithScore = articles.value?.map((el, i) => ({
    item: {
      id: el._path,
      image: el.image.src,
      category: t('blog.category'),
      title: el.title,
      action: t('blog.action'),
      to: articleLink(el._path),
    },
    score:
      (i + 1) / ((articles.value?.length || 0) + 1) +
      (getRandomValue() - 0.5) / 1000,
  }));

  return [...(articlesWithScore || []), ...playlistsWithScore]
    .sort((a, b) => a.score - b.score)
    .map((el) => el.item);
});
</script>

<template>
  <BaseSliderSection
    class="mediaSentences"
    slider-class="mediaSentences__slider"
    slider-slide-class="mediaSentences__slide"
    :list="list"
    slides-per-view="auto"
  >
    <template #title>{{ t('title') }}</template>
    <template #slide="{ item }">
      <NuxtLink v-if="item.to" :to="item.to">
        <BaseMediaCard :media="item" />
      </NuxtLink>
      <BaseMediaCard v-else :media="item" />
    </template>
  </BaseSliderSection>
</template>

<i18n>
ru:
  title: Посмотреть и послушать
  blog:
    category: Блог
    action: Читать

en:
  title: Watch and listen
  blog:
    category: Blog
    action: Read
</i18n>

<style lang="scss">
.mediaSentences {
  & &__slider {
    padding: 0 20px;
  }

  & &__slide {
    width: 416px;
    height: 518px;

    @include mq('sm') {
      width: 100%;
      height: 400px;
    }
  }
}
</style>
